import { format, parse, differenceInYears } from "date-fns";

// Regex for date format yyyy-mm-dd
const serverDate = /^\d{4}-\d{2}-\d{2}$/;

export const parseISODate = (date: string | number | Date) => {
  // eslint-disable-next-line i18next/no-literal-string
  if (typeof date === "string" && serverDate.test(date)) return new Date(`${date}T00:00:00`);
  return new Date(date);
};

export const formatDate = (
  time: string | undefined | Date | number,
  dateFormat = "d MMM yyyy"
) => {
  if (time) return format(parseISODate(time), dateFormat);
  return "";
};

export const calculateAge = (dob: string): number => {
  /* eslint-disable-next-line i18next/no-literal-string */
  const date = parse(dob, "yyyy-MM-dd", new Date());
  const age = differenceInYears(new Date(), date);
  return isNaN(age) ? 0 : age;
};
